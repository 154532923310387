exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-faq-js": () => import("./../../../src/pages/faq.js" /* webpackChunkName: "component---src-pages-faq-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-job-details-js": () => import("./../../../src/pages/job-details.js" /* webpackChunkName: "component---src-pages-job-details-js" */),
  "component---src-pages-landing-2-js": () => import("./../../../src/pages/landing-2.js" /* webpackChunkName: "component---src-pages-landing-2-js" */),
  "component---src-pages-landing-3-js": () => import("./../../../src/pages/landing-3.js" /* webpackChunkName: "component---src-pages-landing-3-js" */),
  "component---src-pages-submit-job-js": () => import("./../../../src/pages/submit-job.js" /* webpackChunkName: "component---src-pages-submit-job-js" */)
}

