export const menuItems = [
  {
    name: "home",
    label: "Home",
    items: [
      { name: "", label: "Home 1" },
      { name: "landing-2", label: "Home 2" },
      { name: "landing-3", label: "Home 3" },
    ],
  },
  {
    name: "pages",
    label: "Pages",
    items: [
      {
        name: "job-pages",
        label: "Job Pages",
        items: [
          { name: "search-grid", label: "Job Grid" },
          { name: "search-list", label: "Job List" },
          { name: "job-details", label: "Job Details" },
        ],
      },
      {
        name: "dashboard",
        label: "Dashboard",
        items: [
          { name: "dashboard-main", label: "Dashboard Main" },
          { name: "dashboard-settings", label: "Dashboard Settings" },
          {
            name: "dashboard-applicants",
            label: "Dashboard Applicants",
          },
          { name: "dashboard-jobs", label: "Dashboard Posted Jobs" },
        ],
      },
      {
        name: "candidate",
        label: "Candidate Pages",
        items: [
          { name: "candidate-profile", label: "Candidate Profile 01" },
          { name: "candidate-profile-2", label: "Candidate Profile 02" },
        ],
      },
      {
        name: "search",
        label: "Search Pages",
        items: [
          { name: "search-grid", label: "Search Grid" },
          { name: "search-list", label: "Search List 01" },
          { name: "search-list-2", label: "Search List 02" },
        ],
      },
      {
        name: "company-profile",
        label: "Company Profile",
      },

      {
        name: "essential",
        label: "Essential",
        items: [
          { name: "faq", label: "FAQ" },
          { name: "404", label: "404" },
          { name: "pricing", label: "Pricing" },
          { name: "contact", label: "Contact" },
        ],
      },
    ],
  },
  {
    name: "https://uxtheme.net/product-support/",
    label: "Support",
    isExternal: true,
  },
];
